import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    sliderContainer: {
        display: "flex",
        overflow: "hidden",
        width: "100%",
        position: "relative",
    },
    sliderWrapper: {
        display: "flex",
        transition: "transform 1s ease-in-out",
    },
    slide: {
        minWidth: "100%",
        boxSizing: "border-box",
    },
    controls: {
        position: "absolute",
        top: "50%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        transform: "translateY(-50%)",
    },
    button: {
        background: "rgba(0, 0, 0, 0.5)",
        color: "white",
        border: "none",
        padding: "10px",
        cursor: "pointer",
    },
});

export default useStyles;